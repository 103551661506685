window.cookieconsent  = initCookieConsent();

window.cookieconsent.run({
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,

    delay: 250,
    revision: 0,

    gui_options: {
        consent_modal: {
            layout: 'bar',
            position: 'bottom center',
            transition: 'slide',
            swap_buttons: false
        },
        settings_modal: {
            layout: 'box',
            transition: 'slide'
        }
    },

    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All", you consent to the use of ALL the cookies.',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Manage Preferences',
                    role: 'settings'
                }
            },
            settings_modal: {
                title: 'Privacy Overview',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.<br>If you are changing your settings, changes will apply only after page refresh.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        },
                        cookie_table: [
                            {
                                col1: 'cc_cookie',
                                col2: 'sbcgaming.com',
                                col3: '6 months',
                                col4: 'This cookie is set to record the users preferences for the cookies.',
                            },
                            {
                                col1: 'PHPSESSID',
                                col2: 'sbcgaming.com',
                                col3: 'session',
                                col4: 'This cookie is native to PHP applications. The cookie is used to store and identify a users\' unique session ID for the purpose of managing user session on the website. The cookie is a session cookies and is deleted when all the browser windows are closed.',
                            }
                        ]
                    }, {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: '^_ga',
                                col2: 'sbcgaming.com',
                                col3: '2 years',
                                col4: 'The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site\'s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'sbcgaming.com',
                                col3: '1 day',
                                col4: 'Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website\'s performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.'
                            },
                            {
                                col1: '_gat_UA-5372665-20',
                                col2: 'sbcgaming.com',
                                col3: '1 minute',
                                col4: '	A variation of the _gat cookie set by Google Analytics and Google Tag Manager to allow website owners to track visitor behaviour and measure site performance. The pattern element in the name contains the unique identity number of the account or website it relates to.'
                            },
                            {
                                col1: '_hjFirstSeen',
                                col2: 'sbcgaming.com',
                                col3: '30 minutes',
                                col4: 'Hotjar sets this cookie to identify a new user\'s first session. It stores a true/false value, indicating whether it was the first time Hotjar saw this user.'
                            },
                            {
                                col1: '_hjIncludedInSessionSample',
                                col2: 'sbcgaming.com',
                                col3: '2 minutes',
                                col4: '	Hotjar sets this cookie to know whether a user is included in the data sampling defined by the site\'s daily session limit.'
                            },
                            {
                                col1: '_hjIncludedInPageviewSample',
                                col2: 'sbcgaming.com',
                                col3: '2 minutes',
                                col4: 'Hotjar sets this cookie to know whether a user is included in the data sampling defined by the site\'s pageview limit.'
                            },
                            {
                                col1: '_hjAbsoluteSessionInProgress',
                                col2: 'sbcgaming.com',
                                col3: '30 minutes',
                                col4: 'Hotjar sets this cookie to detect the first pageview session of a user. This is a True/False flag set by the cookie.'
                            },
                            {
                                col1: '_hjSessionUser_{site_id}',
                                col2: 'sbcgaming.com',
                                col3: '1 year',
                                col4: 'Hotjar sets this cookie to persists the Horjar User ID, which is unique to that site. Cookie holds unique User ID to ensure data from subsequent visits is attributed to the same site to the same user ID. Cookie is set on first pageview. {side_id} is replaced by current site ID.'
                            },
                            {
                                col1: '_hjSession_{site_id}',
                                col2: 'sbcgaming.com',
                                col3: '30 minutes',
                                col4: 'Hotjar sets this cookie to ensure subsequent requests in the session window that are attributed to the same session. Cookie holds current session data and is set on first pageview. {side_id} is replaced by current site ID.'
                            },
                        ]
                    }, {
                        title: 'More information',
                        description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" id="cc-settings-hide-popup" href="/#contact-us">contact us</a>.',
                    }
                ]
            }
        }
    }
});

document.getElementById("cc-settings-hide-popup").addEventListener("click", ()=>{
    window.cookieconsent.hideSettings();
});